import { gql } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { ThemeProvider } from '@emotion/react'
import { CreateRounded } from '@mui/icons-material'
import {
    Box,
    Button,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    Stack,
    createTheme,
    useMediaQuery,
} from '@mui/material'
import moment from 'moment'
import { FunctionComponent, useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import MuiDropdown from '../../components/MuiWrappers/MuiDropdown/MuiDropdown'
import NavBarNew from '../../components/NavBarNew'
import CalendarTemp from '../../components/calendarTemp'
import DayInfo from '../../components/day-info'
import CreateSessionModalNew from '../../components/modals/CreateSessionModalNew/CreateSessionModalNew'
import SessionModalNew from '../../components/modals/SessionModalNew/SessionModalNew'
import AuthContainer from '../../container/AuthContainer'
import {
    EmployerModel,
    LocalityModel,
    LocationModel,
    SelectedTab,
    SessionModel,
} from '../../libary/Models'
import client from '../../libary/apollo'
import useFeatures from '../../libary/hooks/useFeatures'
import { ModalInformation } from '../../libary/redux/interfaces'
import { legacyTheme } from '../../themes'
import { getLocalitiesQuery, getLocationsQuery } from './queries'
import './styles.css'

interface StandardProps {
    modal?: ModalInformation
}

const rotaQuery = gql`
    query sessions(
        $month: String
        $year: String
        $LOCALITY_ID: String
        $LOCATION_ID: String
        $EMPLOYER_ID: String
    ) {
        sessions(
            month: { month: $month, year: $year }
            LOCALITY_ID: $LOCALITY_ID
            LOCATION_ID: $LOCATION_ID
            EMPLOYER_ID: $EMPLOYER_ID
        ) {
            session_date
            session_status
            RECURRENCE_ID
        }
    }
`
const Rota: FunctionComponent<StandardProps> = (props: StandardProps) => {
    const searchParams = new URLSearchParams(window.location.search)
    const [selectedDate, setSelectedDate] = useState(
        searchParams.get('currentMonth') !== null
            ? moment(parseFloat(searchParams.get('currentMonth'))).toDate()
            : new Date()
    )
    const [selectedSessionId, setSelectedSessionId] = useState<string | null>(
        null
    )
    const [selectedSession, setSelectedSession] = useState<
        SessionModel | undefined
    >(undefined)
    const [createSessionDate, setcreateSessionDate] = useState<Date | null>(
        null
    )
    const [locality, setLocality] = useState<LocalityModel | undefined>(
        undefined
    )
    const [localities, setLocalities] = useState<LocalityModel[] | null>(null)
    const [locations, setLocations] = useState<LocationModel[] | undefined>(
        undefined
    )
    const [location, setLocation] = useState<LocationModel | undefined>(
        undefined
    )
    const [employer] = useState<EmployerModel | null>(null)
    const [token, setToken] = useState<string | undefined>(undefined)
    const [reloadCalendar, setReloadCalendar] = useState<boolean>(false)
    const { getAccessTokenSilently } = useAuth0()
    const { features } = useFeatures()

    const muiTheme = createTheme({
        palette: {
            primary: {
                main: legacyTheme().colors.secondary,
            },
        },
    })
    useEffect(() => {
        if (features === null) {
            return
        }
        console.log('FEATURES: ', features)
        if (features.manageRota !== true && features.viewRota !== true) {
            window.location.replace('/no')
        }
    }, [features])

    useEffect(() => {
        ;(async () => {
            const accessToken = token ? token : await getAccessTokenSilently()
            setToken(accessToken)
            client
                .query({
                    query: getLocalitiesQuery,
                    context: {
                        headers: {
                            Authorization: accessToken,
                        },
                    },
                })
                .then((response) => {
                    setLocalities(response.data.getLocalities)
                    if (response.data.getLocalities.length === 1) {
                        setLocality(response.data.getLocalities[0])
                    }
                })
        })()
    }, [getAccessTokenSilently])

    useEffect(() => {
        console.log('ERE')
        if (locality === undefined) {
            return
        }
        client
            .query({
                query: getLocationsQuery,
                variables: {
                    localityId: locality.id,
                },
                context: {
                    headers: {
                        Authorization: token,
                    },
                },
            })
            .then((response) => {
                setLocations(response.data.getLocations)
            })
    }, [locality])

    const _setDate = (date?: Date) => {
        setSelectedDate(date)
    }
    const closeSessionModal = (reload: boolean) => {
        setSelectedSessionId(null)
        setSelectedSession(null)
        if (reload === true) {
            console.log('RELOAD')
            setReloadCalendar(!reloadCalendar)
        }
    }

    const recurrenceCardClicked = (session: SessionModel) => {
        setSelectedSession(session)
    }

    const cardClicked = (cardId: string) => {
        setSelectedSessionId(cardId)
    }
    const sessionCreated = (sessionModel: SessionModel) => {
        console.log(sessionModel)
        setReloadCalendar(!reloadCalendar)
        setcreateSessionDate(null)
    }

    const matches = useMediaQuery('(min-width:600px)')

    const renderFilterBar = () => {
        return (
            <Box
                sx={{
                    paddingLeft: '64px',
                    paddingTop: '16px',
                    paddingBottom: '16px',
                }}>
                <Stack direction='row' spacing={2}>
                    <Box sx={{ width: matches ? '400px' : '200px' }}>
                        <MuiDropdown
                            label='Locality'
                            items={localities}
                            defaultValue={
                                localities.filter(
                                    (item) => item.id === locality?.id
                                )[0]?.id ?? ''
                            }
                            onChange={(value) => {
                                console.log('LOCAL', value)
                                setLocations(undefined)
                                const loc = localities.filter((i) => {
                                    return i.id === value
                                })[0]

                                console.log('LOG: ', loc)
                                setLocality(loc)
                            }}
                            error=''
                            sx={{ width: 'auto' }}
                        />
                    </Box>
                    <Box sx={{ width: matches ? '400px' : '200px' }}>
                        <MuiDropdown
                            label='Location'
                            items={locations}
                            defaultValue={
                                locations?.filter(
                                    (item) => item.id === location?.id
                                )[0]?.id ?? ''
                            }
                            onChange={(value) => {
                                setLocation(
                                    locations.filter((i) => i.id === value)[0]
                                )
                            }}
                            error=''
                        />
                    </Box>
                    <Button
                        variant='contained'
                        onClick={() => {
                            setLocation(undefined)
                            setLocality(undefined)
                            setLocations(undefined)
                        }}>
                        Reset Filters
                    </Button>
                </Stack>
            </Box>
        )
    }

    return (
        <AuthContainer>
            <ThemeProvider theme={muiTheme}>
                <div className='rotaBody'>
                    <NavBarNew
                        setTab={() => {}}
                        selectedTab={SelectedTab.Rota}
                    />
                    <div className='mainBody'>
                        {localities !== null && renderFilterBar()}
                        <div
                            className='rotaContainer'
                            id='rotaContainer'
                            style={{ position: 'relative' }}>
                            <CalendarTemp
                                reloadCalendar={reloadCalendar}
                                disabled={locality === null}
                                setMonth={(newMonth) => {}}
                                currentMonth={
                                    selectedDate ? new Date(selectedDate) : null
                                }
                                canCreate={true}
                                queryName='sessions'
                                query={rotaQuery}
                                LOCALITY_ID={locality ? locality.id : undefined}
                                LOCATION_ID={location ? location.id : undefined}
                                EMPLOYER_ID={employer ? employer.id : undefined}
                                recurrenceEnabled={true}
                                width={window.innerWidth < 600 ? '95%' : '62%'}
                                setDate={(date: Date) => _setDate(date)}
                                createSession={(date) => {
                                    setcreateSessionDate(date)
                                }}
                                filterFilled={false}
                                filterPending={false}
                            />
                            <div className='rota-page-day-info'>
                                <DayInfo
                                    reload={reloadCalendar}
                                    width='33%'
                                    date={selectedDate}
                                    cardClicked={(cardId, session) => {
                                        if (
                                            session.id.includes(
                                                'RECURRENCE'
                                            ) === true
                                        ) {
                                            recurrenceCardClicked(session)
                                        } else {
                                            cardClicked(cardId)
                                        }
                                    }}
                                    LOCALITY_ID={
                                        locality ? locality.id : undefined
                                    }
                                    LOCATION_ID={
                                        location ? location.id : undefined
                                    }
                                    EMPLOYER_ID={
                                        employer ? employer.id : undefined
                                    }
                                    recurrenceEnabled={false}
                                />
                            </div>
                            {createSessionDate !== null && (
                                <CreateSessionModalNew
                                    show={createSessionDate !== null}
                                    createSessionDate={createSessionDate}
                                    closeModal={(reload) => {
                                        setcreateSessionDate(null)
                                        setReloadCalendar(!reloadCalendar)
                                    }}
                                    createSessionCallback={sessionCreated}
                                    selectedLocality={locality}
                                    selectedLocation={location}
                                />
                                // <CreateSessionModal
                                //     show={createSessionDate !== null}
                                //     createSessionDate={createSessionDate}
                                //     closeModal={() => setcreateSessionDate(null)}
                                //     createSessionCallback={sessionCreated}
                                //     selectedLocality={locality}
                                //     selectedLocation={location}
                                // />
                            )}
                        </div>
                        {selectedSessionId !== null && (
                            <SessionModalNew
                                show={true}
                                id={selectedSessionId}
                                closeModal={(reload: boolean) => {
                                    closeSessionModal(reload)
                                }}
                            />
                        )}
                        {/* Recurrence Modals */}
                        {selectedSession && (
                            <SessionModalNew
                                show={true}
                                id={''}
                                sessionModel={selectedSession}
                                closeModal={(reload: boolean) => {
                                    closeSessionModal(reload)
                                }}
                            />
                        )}
                        <SpeedDial
                            ariaLabel='SpeedDial basic example'
                            sx={{
                                position: 'absolute',
                                bottom: 64,
                                right: 16,
                                '.MuiSpeedDial-fab': {
                                    backgroundColor: legacyTheme().colors.brand,
                                },
                            }}
                            icon={<SpeedDialIcon />}>
                            {[
                                {
                                    name: 'Create a Scheduled Session',
                                    icon: <CreateRounded />,
                                },
                            ].map((action) => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={() => {
                                        setcreateSessionDate(new Date())
                                    }}
                                />
                            ))}
                        </SpeedDial>
                    </div>
                    <Footer />
                </div>
            </ThemeProvider>
        </AuthContainer>
    )
}

export default Rota
