import { gql } from "@apollo/client";
import { sha256 } from "js-sha256";
import { AuthResponse, StaffModel } from "../Models";
import client from "../apollo";

export interface RegisterRepsonse {
  success: boolean;
  token: string;
  staff_type: string;
  id: string;
}
const encryptPassword = async (password) => {
  return new Promise((resolve, reject) => {
    var hash = sha256.create();
    hash.update(password);
    let hex = hash.hex();
    resolve(hex);
  });
};
class Auth {
  static authenticateUser = (token, email): Promise<AuthResponse> => {
    return new Promise<AuthResponse>((resolve, reject) => {
      client
        .mutate({
          mutation: gql`
            mutation auth($email: String) {
              auth(email: $email) {
                id
                success
                token
                me {
                  id
                  name_value
                  staff_type
                  account_state
                  image
                }
                features {
                  findSessions
                  manageRota
                  viewStaffList
                  approveStaff
                  viewAdmins
                  editPermissions
                }
              }
            }
          `,
          variables: {
            email,
          },
          fetchPolicy: "network-only",
          context: {
            headers: {
              authorization: token,
            },
          },
        })
        .then((response) => {
          const me = JSON.stringify(response.data.auth.me);
          sessionStorage.setItem("user", me);
          resolve(response.data.auth);
        });
    });
  };

  static login = (email, password) => {
    return new Promise((resolve, reject) => {
      encryptPassword(password).then((encryptedPassword) => {
        client
          .query({
            query: gql`query { login(email: "${email}", password: "${encryptedPassword}"){id success token staff_type}}`,
          })
          .then((response) => {
            switch (response.data.login.staff_type) {
              case "ADMIN":
                resolve({
                  success: response.data.login.success,
                  token: response.data.login.token,
                  staff_type: response.data.login.staff_type,
                  id: response.data.login.id,
                });
                break;
              default:
                resolve({
                  success: response.data.login.success,
                  token: response.data.login.token,
                  staff_type: response.data.login.staff_type,
                  id: response.data.login.id,
                });
                break;
            }
            resolve(response.data.login);
          });
      });
    });
  };

  static newRegister = (token, email) => {
    const mutation = gql`
            mutation {
                newRegister(email: ${email}) {
                    success
                    token
                }
            }
        `;
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          context: {
            headers: {
              authorization: token,
            },
          },
        })
        .then((response) =>
          resolve({ success: response.data.register.success })
        );
    });
  };

  static register = (staff: StaffModel) => {
    const mutation = gql`
        mutation{
            register(data: {
                email:"${staff.email}", 
                password:"${staff.password}", 
                name_value:"${staff.name_value}", 
                staffType: "${staff.staff_type}"
            }){
              success
              token
            }
          }
        `;
    return new Promise<RegisterRepsonse>((resolve, reject) => {
      client.mutate({ mutation: mutation }).then((response) => {
        resolve({
          success: response.data.register.success,
          token: response.data.register.token,
          staff_type: staff.staff_type,
          id: response.data.register.id,
        });
      });
    });
  };
}

export default Auth;
